import { Link, graphql } from "gatsby";
import React from "react";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { PipeDriveForm } from "~components/utils/pipedrive-form";
import * as styles from "./index.module.scss";

type Props = {
  data: KenroDownloadServicePage;
};

const KENRO_CONTACT_FORM_ID =
  "2TUT8s6ycbw8ytRbOIu7rcX8Oh3vMrVzXYBrInTATNc5HEMqHbwy1P1DT1XCaklDJ";

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  return (
    <Kenro2PaneLayout
      meta={{
        title: "KENRO (ケンロー) サービス概要資料",
        description:
          "KENRO (ケンロー) サービス概要資料のダウンロードページです。",
      }}
      isSimple={true}
    >
      <div className={styles.Download}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Download_Container}>
            <div className={styles.Download_Content}>
              <h1 className={styles.Download_Content_Title}>
                KENRO (ケンロー) サービス概要資料
              </h1>
              <div className={styles.Download_Content_Image}>
                <img
                  className={styles.Slide_Image}
                  src={data?.slide?.publicURL || ""}
                  alt="サービス概要資料のイメージ"
                />
              </div>
              <div className={styles.Download_Content_Description}>
                <h2>この資料で分かること</h2>
                <ul>
                  <li>まずはKENROがどのようなものか知りたい</li>
                  <li>
                    機能やコンテンツについて詳しく知りたい
                    <li> 他社の活用事例が知りたい</li>
                  </li>
                  <li>社内で検討に上げるため、料金について詳しく知りたい</li>
                </ul>
              </div>
              <div className={styles.Download_Content_Text}>
                <p>ご興味をお持ちいただき、誠にありがとうございます。</p>
                <p>
                  KENROは、システム開発会社での大規模な人材教育から、ベンチャー企業での自社開発チームのセキュリティ学習まで、幅広く導入いただいています。
                </p>
                <p>
                  <b>
                    「事例をもっと知りたい」「管理機能のデモを見たい」「見積もりを依頼したい」
                  </b>
                  など、お困りごとやご不明点がございましたら、
                  <Link to="/kenro/contact">お問い合わせフォーム</Link>
                  よりお気軽にお問い合わせください。
                </p>
              </div>
            </div>
            <div className={styles.Download_Form}>
              <p className={styles.Download_Form_Title}>
                フォーム送信後、ダウンロードURLを記載したメールをお送りします。
              </p>
              <PipeDriveForm formID={KENRO_CONTACT_FORM_ID} />
            </div>
          </div>
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

export const query = graphql`
  query KenroDownloadServicePage {
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
    slide: file(relativePath: { eq: "kenro/download/service_slide.svg" }) {
      publicURL
    }
  }
`;

export default Component;
